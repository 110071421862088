import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { ProjectListing, Layout } from 'components';

const Digital = ({
  data: {
    allMarkdownRemark: { edges: projectEdges },
  },
}) => (
  <Layout>
    <ProjectListing title="Digital Designer" projectEdges={projectEdges} />
  </Layout>
);

export default Digital;

Digital.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query DigitalQuery {
    allMarkdownRemark(filter: { frontmatter: { categories: { in: ["digital"] } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            categories
            cover {
              childImageSharp {
                fluid(maxWidth: 850, quality: 90, traceSVG: { color: "#f3f3f3" }) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
